import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { ResponseItemBean } from '../model/model';
import { Observable } from 'rxjs';
import { UserDto } from '../model/dto/userDto';

@Injectable()
export class UserService extends BaseRequestService {

    me(): Observable<ResponseItemBean<UserDto>> {
        return this.get2('user/me');
    }
}
